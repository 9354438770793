// app theme colors

$success: #5ca80a;
$primary: $success;
$secondary: $gray-600;
// $success: $green;
// $info: $blue;
// $warning: $yellow;
// $danger: $red;
// $light: $gray-100;
// $dark: $gray-800;
